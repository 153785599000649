import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/mpsk-logo.jpeg';
import facebook from '../img/social/facebook.svg';
import youtube from '../img/social/youtube.svg';
import twitter from '../img/social/twitter.svg';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="content">
                <div className="content has-text-centered">
                  <img src={logo} alt="MPSK" style={{ width: '9em', height: '9em' }} />
                </div>
              </div>
              <div className="content has-text-centered has-background-black has-text-white-ter">
                <div className="container has-background-black has-text-white-ter">
                  <div className="columns has-shadow columns-divider">
                    <div className="column is-4">
                      <section className="menu">
                        <ul className="menu-list">
                          <li>
                            <Link to="/" className="navbar-item">
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/about">
                              About
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/findus">
                              Find us
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/blog">
                              Latest Stories
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/contact">
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </section>
                    </div>
                    <div className="column is-4">
                      <section>
                        <ul className="menu-list">
                          <li>
                            <Link className="navbar-item" to="/acceptableUsePolicy">
                              Acceptable Use Policy
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/privacyPolicy">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link className="navbar-item" to="/termsOfWebsiteUse">
                              Terms of Website Use
                            </Link>
                          </li>
                        </ul>
                      </section>
                    </div>
                    <div className="column is-4 social">
                      <section className="social-icons">
                        <a title="MPSK - facebook" href="https://www.facebook.com/PeterboroughMPSK/?rc=p">
                          <img src={facebook} alt="MPSK - Facebook" style={{ width: '1em', height: '1em' }} />
                        </a>
                        <a title="MPSK - twitter" href="https://twitter.com/mpsk_peterboro">
                          <img
                            className="fas fa-lg"
                            src={twitter}
                            alt="MPSK - Twitter"
                            style={{ width: '1em', height: '1em' }}
                          />
                        </a>
                        <a title="MPSK - youtube" href="https://www.youtube.com/channel/UCeN_NRcPr0t7Us7K2gdtryg/">
                          <img src={youtube} alt="MPSK - youtube" style={{ width: '1em', height: '1em' }} />
                        </a>
                      </section>
                    </div>
                  </div>
                  <div className="columns columns-divider">
                    <div className="column is-12">
                      <div className="content has-text-centered">
                        <p>
                          <strong className="has-text-white-bis">
                            &copy; {new Date().getFullYear()} MPSK Limited trading as Master Parker Serial Kickers.{' '}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
